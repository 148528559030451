import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import HorizontalCard from '../components/HorizontalCard'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import Map from '../components/Map'
import { useNavigate } from 'react-router-dom'
import { postNewsletter } from '../services/api/newsletterApi'
import { getEvento } from '../services/api/eventsApi'
import { toast } from 'react-toastify';

import menu_image from '../assets/images/sfondo.jpg';
import basi_image from '../assets/images/base_singola.jpg'
import regalo from '../assets/images/regalo.png';

function Home() {
  const [email, setEmail] = useState();
  const [personalDataCheckbox, setPersonalDataCheckbox] = useState();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openGift, setOpenGift] = useState(false);
  const [today, setToday] = useState();
  const [events, setEvents] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    let date = new Date();
    let month;
    switch (date.getMonth() + 1) {
      case 1:
        month = 'Gennaio'
        break;
      case 2:
        month = 'Febbraio'
        break;
      case 3:
        month = 'Marzo'
        break;
      case 4:
        month = 'Aprile'
        break;
      case 5:
        month = 'Maggio'
        break;
      case 6:
        month = 'Giugno'
        break;
      case 7:
        month = 'Luglio'
        break;
      case 8:
        month = 'Agosto'
        break;
      case 9:
        month = 'Settembre'
        break;
      case 10:
        month = 'Ottobre'
        break;
      case 11:
        month = 'Novembre'
        break;
      case 12:
        month = 'Dicembre'
        break;
      default:
        break;
    }
    setToday(date.getDate() + ' ' + month)
    getData();
  }, [])

  async function getData() {
    let res = await getEvento();
    if (res.status === 200) {
      setEvents(res.data.result);
    }
  }

  function checkEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function handleEmailField(evt) {
    if (evt.target.value !== '' && !isEmailValid) {
      setIsEmailValid(true)
    }
    setEmail(evt.target.value);
  }

  function handlePersonalDataCheckbox(evt) {
    setPersonalDataCheckbox(evt.target.checked);
  }

  async function handleOnSubmit() {
    let emailValid = checkEmail(email);
    setIsEmailValid(emailValid);
    if (emailValid) {
      setLoading(true);
      let res = await postNewsletter(email);
      if (res.status === 200) {
        toast.success('Iscrizione avvenuta con successo!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          closeButton: false,
          style: {
            background: '#4F0F0F',
            color: 'white',
            borderRadius: '20px',
            border: '1px solid white'
          },
        });
        setEmail('');
        setIsEmailValid(true);
        setPersonalDataCheckbox(false);
      } else {
        toast.error('Iscrizione fallita! Errore del server', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          closeButton: false,
          style: {
            background: '#4F0F0F',
            color: 'white',
            borderRadius: '20px'
          },
        });
      }
      setLoading(false);
    }
  }

  function onNavigateButtons(page) {
    navigate(`${page}`);
  }

  return (
    <>
      <Header />
      <div className='background-container d-flex align-items-end'>
        <div className='bg-overlay' />
        <div className='home-title-container'>
          <h1 className='text-white'>Pinseria Le Tre Farine</h1>
          <hr className='divider' />
          <h2 className='text-white'>L’antica pizza romana</h2>
        </div>
      </div>

      <div className='news-section'>
        {/* <h2 className='gift-title mb-4 text-center'>Pinsa del giorno</h2> */}
        <div className='news-text-container text-white'>
          {/* <img src={regalo} className={`gift-image ${!openGift && 'd-none'} top-right`} /> */}
          <div className='title-container'>
            {/* <div className={`${openGift ? 'news-subtitle' : 'news-title'} mb-2`}>{today}</div> */}
            {/* <img src={regalo} className={`gift-image ${!openGift ? 'gift-closed' : 'd-none'}`} onClick={() => setOpenGift(true)} /> */}
            <div className={` news-title`}>{events?.title}</div>
            <div className={` news-title`}>{events?.subtitle}</div>
          </div>
          {/* <div className='news-title'>Una forma ovale, un mix di farine di frumento, soia e riso, un’idratazione dell’80%, 24-48 ore di lievitazione:</div> */}
          {/* <div className='news-title'>sono questi i segreti che rendono la Pinsa leggera e unica nel suo genere!</div> */}
          {/* <img src={regalo} className={`gift-image ${!openGift && 'd-none'} top-left`} /> */}
        </div>
      </div>
      <div className='home-quote'>
        <div className='news-dark-overlay' />
        <div className='quote-text'>
          <span>
            Dal latino "pinsere" (allungare, stendere) la Pinsa trae origine dall'antica Roma: i contadini, grazie alla macinazione dei cereali (miglio. orzo e farro) e all'aggiunta di sale ed erbe aromatiche, preparavano queste focacce.<br />
            Oggi, frutto di una serie di rivisitazioni, la Pinsa si puó gustare in tutta la sua bontá. Molto digeribile e con meno carboidrati e grassi rispetto alla pizza classica, ha come ingrediente base tre farine (frumento, soia e riso) e pasta madre</span>
        </div>
      </div>
      <HorizontalCard title={'Un menù per tutti!'} subtitle={'Le nostre proposte spaziano da gusti classici per gli amanti della tradizione a gusti speciali per gli amanti della ricercatezza. Puoi consultarlo qui!'} btnLabel={'Menù'} onClick={onNavigateButtons.bind(this, '/menu')} img={menu_image} />
      <HorizontalCard reverse={true} title={'Le nostre basi in ATM'} subtitle={'Per il servizio di vendita ad attività ristorative di basi-pinsa in atmosfera protettiva, puoi consultare la parte del sito dedicata e contattarci per maggiori informazioni.'} btnLabel={'Basi'} onClick={onNavigateButtons.bind(this, '/factory')} img={basi_image} />
      <hr className='home-vector' />
      <div className='newsletter-section'>
        <div className='text-black newsletter-subtitle text-center'>
          Vuoi rimanere sempre aggiornato sulle novitá e gli eventi che organizziamo?
        </div>
        <div className='text-black newsletter-title fw-bold text-center'>
          Iscriviti alla nostra newsletter!
        </div>
        <div className='form-container'>
          <div className=''>
            <FloatingLabel
              label="Email"
              className="floating-field">
              <Form.Control
                type="email"
                placeholder="name@example.com"
                onChange={handleEmailField}
                value={email}
                isInvalid={!isEmailValid}
              />
              <Form.Control.Feedback type='invalid'>
                Inserire un'email valida
              </Form.Control.Feedback>
            </FloatingLabel>
            <a href='https://res.cloudinary.com/dkolzip41/image/upload/v1703948744/file/ph2srsow7o0ji7rwmlr8.pdf' target='_blank'>
              <Form.Check
                type='checkbox'
                label={`Dichiaro di aver letto e accettato la nostra informativa sulla privacy`}
                className='mt-3 check-field'
                checked={personalDataCheckbox}
                onChange={handlePersonalDataCheckbox}
              />
            </a>
          </div>
          <div className='d-flex align-items-start h-100'>
            {!loading ?
              <Button
                variant='primary'
                className='button-text'
                size='lg'
                disabled={!email || !personalDataCheckbox}
                onClick={handleOnSubmit}
              >
                Invia
              </Button>
              :
              <div class="spinner-border text-primary" role="status" />
            }
          </div>

        </div>
      </div>
      <div className='map-section mt-4 mb-5'>
        <div className='map-title text-black fw-bold text-center mb-4'>
          Inoltre puoi trovarci qui:
        </div>
        <div className='w-75'>
          <Map />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home