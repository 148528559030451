import React from 'react'

function Card(props) {
    return (
        <div className='card-container'>
            <img
                src={props.img}
                className='card-image'
                alt='pinsa'
            />
            <div className='card-description'>
                <div className='card-title'>{props.name}</div>
                <div className='card-price'>{parseFloat(props.price).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</div>
                <span className='card-text lh-1'>{props.description}</span>
            </div>

        </div>
    )
}

export default Card