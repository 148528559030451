import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import foto from '../assets/images/michael_singolo.jpg'

function About() {
  return (
    <div id='about'>
      <Header />
      <div className='background-container d-flex align-items-end text-center'>
        <div className='bg-overlay' />
        <div className='about-title-container'>
          <h1 className='text-white mb-4 fw-bold'>Pinseria le tre farine</h1>
          <h2 className='text-white fw-bold'>Sforniamo pinse dal 2019</h2>
        </div>
      </div>
      <div className='story-container mb-4 mt-4'>
        <h1 className='mb-4 mt-4 fw-bold'>Storia</h1>
        <div className='story-body'>
          <p className='about-text'>
            Come sempre, dietro la storia di qualcosa c’è la storia di qualcuno.<br />
            Michael, il nostro pinsaiolo, nasce e cresce in una famiglia di ristoratori, tra pentole e fornelli. Fin da giovanissimo inizia a lavorare nell’impresa familiare dei suoi genitori, un bar-trattoria che offre tavola calda, come cameriere e aiuto cuoco. È proprio lavorando in cucina che capisce che questo ambiente è il suo futuro.
            Dopo due anni da dipendente presso una pizzeria d’asporto, decide a soli 25 anni di aprire una pizzeria. La pizza diventa la sua passione, e ne sperimenta diverse tipologie: al trancio, contemporanea, con differenti farine,… L’unica che non può imparare a produrre da autodidatta, per il suo particolare processo di realizzazione, è la Pinsa. Si iscrive così al “Corso di pinsa romana”, fondato e tenuto dalla rinomata “Scuola di Pinsa romana – Pinsa School”, che gli insegna le tecniche di realizzazione, stesura e lievitazione dell’impasto.<br />
            Il 10 maggio 2019 apre la sua pinseria in Via Sandro Pertini 24 a Bareggio, effettuando dal martedì alla domenica servizio d’asporto e domicilio nei comuni limitrofi.
            Dal 2023 si attrezza anche per vendere le basi in ATM da lui prodotte a bar e ristoranti che ne sono interessati.<br />
            Ad oggi Michael continua a volersi rinnovare, restando sempre aggiornato su come si evolve il suo amato mondo della pizza.
          </p>
          <img
            src={foto}
            className='about-img'
            alt='about'
          />
        </div>
        {/* <p className='about-text'>
          
        </p> */}

      </div>

      <Footer />
    </div>
  )
}

export default About