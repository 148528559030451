import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useState } from 'react'
import { useEffect } from 'react'
import Collapsable from '../components/Collapsable'
import { getPinse } from '../services/api/pinseApi'
import ModalImage from "react-modal-image";

function Menu() {

  const [pinse, setPinse] = useState();
  const [pinsaGiorno, setPinsaGiorno] = useState();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let res = await getPinse();
    setPinse(res.data.result.sort((a, b) => a.price - b.price));
    let giorno = res.data.result.find((a) => a.giorno == true);
    console.log(giorno);
    setPinsaGiorno(giorno);
    // setPinsaGiorno(res.data.result[2]);

  }

  function scrollToAllergens(event) {
    event.preventDefault(); // Per evitare il comportamento predefinito del link
    const allergensElement = document.getElementById("allergens");
    if (allergensElement) {
      const offset = 300; // Offset desiderato
      const scrollTop = allergensElement.offsetTop - offset;
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth", // Puoi rimuovere questa linea se desideri uno scroll istantaneo
      });
    }
  }

  return (
    <div className='menu-container '>
      <Header />
      <div className='pinsa-day-container ' />
      <div className='d-flex flex-direction-row down-triangle'>
        <div className='triangle left-triangle' />
        <div className='triangle right-triangle' />
      </div>
      <div className='menu mb-4 mt-4'>
        <h1 className='mt-4 fw-bold'>Menù</h1>
        <div className='text-center mb-4'>I nostri prodotti potrebbero contenere tracce di allergeni. <a to="#" onClick={scrollToAllergens}>Scopri di più</a> </div>
        {pinsaGiorno &&
          <div className='d-flex justify-content-center align-items-center mt-4 mb-4'>
            <div className='day-card'>
              <img
                src={`${pinsaGiorno.image}`}
                className='pinsa-day-img'
                alt='pinsa-day'
              />
              <div className='card-text'>
                <h4 className='fw-bold pb-2'>Pinsa del giorno:</h4>
                <div className='text-center'>
                  <h2 className='fw-bold'>{pinsaGiorno.name}</h2>
                  <p className='lh-1'>{pinsaGiorno.description}</p>
                  <p className='lh-1 mb-0'>{parseFloat(pinsaGiorno.price).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</p>
                </div>
              </div>
            </div>
          </div>
        }
        <Collapsable model={pinse} type={'CLASSICA'} label='classiche' />
        <Collapsable model={pinse} type={'SPECIALE'} label='speciali' />
        <Collapsable model={pinse} type={'VEGANA'} label='vegane' />
        <Collapsable model={pinse} type={'DOLCE'} label='dolci' />
      </div>
      {/* <a href="%PUBLIC_URL%/assets/docs/Volantino.pdf" download>Scarica menu</a> */}
      <div id='allergens' className='mt-4 d-flex justify-content-center'>
        <ModalImage
          small={require('../assets/images/allergeni.jpg')}
          large={require('../assets/images/allergeni.jpg')}
          className='allergens-img'
          alt='allergens'
        />
        {/* <img
          src={require('../assets/images/allergeni.jpg')}
          className='allergens-img'
          alt='allergens'
        /> */}
      </div>
      <Footer />
    </div>
  )
}

export default Menu