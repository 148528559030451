import axios from "axios";
import PROPERTIES from "../utils/properties";

//instanza axios per chiamate non autenticate
const axiosInstance = axios.create({
  baseURL: PROPERTIES.BASEURL,
  timeout: PROPERTIES.TIMEOUT,
});

//instanza axios per chiamate con richiesta di autenticazione
const axiosInstanceToken = axios.create({
  baseURL: PROPERTIES.BASEURL,
  timeout: PROPERTIES.TIMEOUT,
});

export async function responseApi(response) {
  return {
    data: response?.data,
    status: response?.status,
  };
}

export async function responseError(error) {
  return {
    message: error?.message,
    status: error?.response?.status,
  };
}

export async function getApi(resource) {
  return axiosInstanceToken
    .get(resource)
    .then((response) => {
      return responseApi(response);
    })
    .catch((error) => {
      return responseError(error);
    });
}

export async function getApiNoAuth(resource) {
  // axiosInstance.defaults.headers = { "Access-Control-Allow-Origin": "*" }
  return axiosInstance
    .get(resource)
    .then((response) => {
      return responseApi(response);
    })
    .catch((error) => {
      return responseError(error);
    });
}

export async function postApi(resource, obj) {
  return axiosInstanceToken
    .post(resource, obj)
    .then((response) => {
      return responseApi(response);
    })
    .catch((error) => {
      return responseError(error);
    });
}

export async function postApiNoAuth(resource, obj) {
  return axiosInstance
    .post(resource, obj)
    .then((response) => {
      return responseApi(response);
    })
    .catch((error) => {
      return responseError(error);
    });
}

export async function putApi(resource, obj) {
  return axiosInstanceToken
    .put(resource, obj)
    .then((response) => {
      return responseApi(response);
    })
    .catch((error) => {
      return responseError(error);
    });
}

export async function deleteApi(resource) {
  return axiosInstanceToken
    .delete(resource)
    .then((response) => {
      return responseApi(response);
    })
    .catch((error) => {
      return responseError(error);
    });
}
