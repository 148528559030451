import './App.scss';
import Home from './views/Home';
import { Route, Routes } from 'react-router-dom';
import Menu from './views/Menu';
import About from './views/About';
import Factory from './views/Factory';
import Contacts from './views/Contacts';
import ScrollToTop from 'react-scroll-to-top';
import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    // Inserisci qui il codice di tracciamento di Google Analytics
    // Assicurati di includere il codice di tracciamento nel tag <head> del tuo sito.
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-PVFTK6YMK0';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-PVFTK6YMK0');
    };
  }, []);
  return (
    <>
      <ScrollToTop smooth style={{
        borderRadius: '20px',
        padding: '15px',
        height: 'auto',
        width: 'auto',
        zIndex: 10,
        opacity: 0.9
      }} />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/menu' element={<Menu />} />
        <Route path='/about' element={<About />} />
        <Route path='/factory' element={<Factory />} />
        <Route path='/contacts' element={<Contacts />} />
      </Routes>
    </>

  );
}

export default App;
