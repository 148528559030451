import React from 'react'
import { BsFacebook, BsInstagram, BsTiktok, BsWhatsapp, BsFillTelephoneFill } from "react-icons/bs"
import { SiJusteat } from "react-icons/si"


function Footer() {
  return (
    <footer className='footer-container'>
      <div className='d-flex flex-direction-row'>
        <div className='triangle left-triangle' />
        <div className='triangle right-triangle' />
      </div>
      <div className='footer-body'>
        <div className='footer-sections'>
          <div className='time-container'>
            <div className='d-flex flex-column w-75 justify-content-center'>
              <h3>Orari di apertura:</h3>
              <div className='w-100 mb-2 mt-2 d-flex justify-content-between'>
                <div>Lunedì:</div><div>Chiuso</div>
              </div>
              <div className='w-100 mb-2 d-flex justify-content-between'>
                <div>Da Martedì a Venerdì:</div> <div>18:00 - 21:30</div>
              </div>
              <div className='w-100 mb-2 d-flex justify-content-between'>
                <div>Sabato:</div>
                <div className='d-flex flex-column'><div>12:00 - 14:00</div><div>18:00 - 21:30</div></div>
              </div>
              <div className='w-100 mb-2 d-flex justify-content-between'>
                <div>Domenica:</div><div>18:00 - 21:30</div>
              </div>{/*
              <div className='mt-3 mb-2'>Via Sandro Pertini 6 - 20008 Bareggio (MI)</div>
              <div >P.IVA 11083260962</div> */}
            </div>
          </div>
          <div className='social-container'>
            <div className='w-75'>
              <a href='https://www.justeat.it/restaurants-pinseria-le-tre-farine-cornaredo/menu' target='_blank' rel="noreferrer">
                <SiJusteat className='text-white' size={20} /> Pinseria Le Tre Farine
              </a>
              <a href='tel:+393479385308' className='mt-3'>
                <BsFillTelephoneFill className='text-white' size={20} />
                +39 347 938 5308
              </a>
              <a href='whatsapp://send?phone=+393479385308' target='_blank' className='mt-3' rel="noreferrer">
                <BsWhatsapp className='text-white' size={20} /> +39 347 938 5308
              </a>
              <a href='https://www.facebook.com/PinserialeTreFarineBareggio/' target='_blank' className='mt-3' rel="noreferrer">
                <BsFacebook className='text-white' size={20} /> @PinserialeTreFarineBareggio
              </a>
              <a href='https://www.instagram.com/pinseria.letrefarine/' target='_blank' className='mt-3' rel="noreferrer">
                <BsInstagram className='text-white' size={20} /> @pinseria.letrefarine
              </a>
              {/* <a href='https://www.tiktok.com/@pinseriale3farine?lang=it-IT' target='_blank' className='mt-3' rel="noreferrer">
                <BsTiktok className='text-white' size={24} /> @pinseriale3farine
              </a> */}
            </div>
          </div>
        </div>
        <div className='text-white mt-4 text-center'>Via Sandro Pertini 24 - 20008 Bareggio (MI) | P.IVA 10713650967</div>
        <span className='text-white mb-3 text-center'>© Pinseria le 3 Farine - Tutti i diritti riservati</span>
      </div>
    </footer>
  )
}

export default Footer