import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { useState } from 'react'
import cloneDeep from 'lodash/cloneDeep';
import basi_doppia from '../assets/images/base_doppia.jpg';
import { postInfoPinsa } from '../services/api/infopinsaApi'
import { toast } from 'react-toastify';

function Factory() {

  const [form, setForm] = useState({
    name: null,
    surname: null,
    email: null,
    phone: null,
    notes: null
  });
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [personalDataCheckbox, setPersonalDataCheckbox] = useState();
  const [loading, setLoading] = useState(false);


  function checkEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function handleOnChange(evt) {
    const newState = cloneDeep(form);
    if (evt.target.name !== 'phone' || (evt.target.name === 'phone' && evt.target.value.length <= 10)) {
      newState[evt.target.name] = evt.target.value;
    }
    setForm(newState)
    if (evt.target.name === 'email' && evt.target.value !== '' && !isEmailValid) setIsEmailValid(true);
  }

  async function handleOnSubmit() {
    let emailValid = checkEmail(form.email);
    setIsEmailValid(emailValid);
    if (emailValid) {
      setLoading(true);
      let res = await postInfoPinsa(form);
      if (res.status === 200) {
        toast.success('Richiesta inviata con successo!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          closeButton: false,
          style: {
            background: '#4F0F0F',
            color: 'white',
            borderRadius: '20px',
            border: '1px solid white'
          },
        });
        setForm({
          name: '',
          surname: '',
          email: '',
          phone: '',
          notes: ''
        });
        setIsEmailValid(true);
        setPersonalDataCheckbox(false);
      } else {
        toast.error('Invio richiesta fallito! Errore del server', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          closeButton: false,
          style: {
            background: '#4F0F0F',
            color: 'white',
            borderRadius: '20px'
          },
        });
      }
      setLoading(false);
    }
  }

  function handlePersonalDataCheckbox(evt) {
    setPersonalDataCheckbox(evt.target.checked);
  }

  return (
    <div id='factory'>
      <Header />
      <div className='header-background' />
      <div className='d-flex flex-direction-row down-triangle'>
        <div className='triangle left-triangle' />
        <div className='triangle right-triangle' />
      </div>
      <div className='factory-body mt-4 text-justify'>
        <h1 className='mt-4 fw-bold'>Basi della pinsa</h1>
        <p className='mt-3 factory-text'>
          La Pinseria Le Tre Farine offre anche un servizio diretto ad attività ristorative quali bar, ristoranti, pub e locali, attraverso la vendita in ATM (atmosfera protettiva) di basi prodotte artigianalmente.
        </p>
        <img
          src={basi_doppia}
          className='factory-img'
          alt='factory'
        />
        <p className='mt-4 factory-text'>
          Il processo di produzione e confezionamento delle basi è interamente svolto dal nostro pinsaiolo: viene prodotto e steso l’impasto, precotto e realizzata la base-pinsa, che viene poi posta, per mezzo dell’utilizzo di un macchinario altamente professionale e apposito, in atmosfera protettiva, pronta per essere venduta. Grazie alla conservazione in atmosfera protettiva, la base-pinsa può essere aperta e consumata fino a 60 giorni successivi al suo confezionamento, condizione che la rende facilmente fruibile e molto amata dai proprietari di bar e ristoranti, che possono farcirla a piacimento e servirla sulle loro tavole.
        </p>
        <hr className='vector mt-4 mb-4' />
        <h3 className='mt-3 text-center fw-bold'>
          Se sei interessato a questo tipo di servizio, compila i campi di seguito e premi “Invia”; ti ricontatteremo noi!
        </h3>
        <form className='factory-form'>
          <div className='factory-fields'>
            <FloatingLabel
              label="Nome"
              className="floating-field"
            >
              <Form.Control
                type="text"
                placeholder="nome"
                name='name'
                className='text-capitalize w-100'
                onChange={handleOnChange}
                value={form.name}
              />
            </FloatingLabel>
            <FloatingLabel
              label="Cognome"
              className="floating-field"
            >
              <Form.Control
                type="text"
                placeholder="cognome"
                name='surname'
                className='text-capitalize w-100'
                onChange={handleOnChange}
                value={form.surname}
              />
            </FloatingLabel>
          </div>
          <div className='factory-fields'>
            <FloatingLabel
              label="Email"
              className="floating-field"
            >
              <Form.Control
                type="email"
                placeholder="email"
                name='email'
                onChange={handleOnChange}
                value={form.email}
                isInvalid={!isEmailValid}
              />
              <Form.Control.Feedback type='invalid'>
                Inserire un'email valida
              </Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel
              label="Telefono"
              className="floating-field"
            >
              <Form.Control
                type="number"
                placeholder="Telefono"
                name='phone'
                onChange={handleOnChange}
                value={form.phone}
              />
            </FloatingLabel>
          </div>
          <FloatingLabel
            label="Note"
            className="floating-field textarea"
          >
            <Form.Control
              as="textarea"
              placeholder="Note"
              rows={4}
              name='notes'
              onChange={handleOnChange}
              value={form.notes}
            />
          </FloatingLabel>
          <a href='https://res.cloudinary.com/dkolzip41/image/upload/v1703948744/file/ph2srsow7o0ji7rwmlr8.pdf' target='_blank'>
            <Form.Check
              type='checkbox'
              label={`Dichiaro di aver letto e accettato la nostra informativa sulla privacy`}
              className='mt-3'
              checked={personalDataCheckbox}
              onChange={handlePersonalDataCheckbox}
            />
          </a>
          {!loading ?
            <Button
              variant='primary'
              className='button-text mt-1 mb-4'
              size='lg'
              disabled={!isEmailValid || !form.name || !form.surname || !form.email || !form.phone || !personalDataCheckbox}
              onClick={handleOnSubmit}
            >
              Invia
            </Button>
            :
            <div class="spinner-border text-primary" role="status" />
          }
        </form>
      </div>
      <Footer />
    </div>
  )
}

export default Factory